import { srGet, srPost, srUpload } from '@sevenrooms/core/api'
import {
  GETTING_WIDGET_SETTINGS,
  GET_SUCCESS_WIDGET_SETTINGS,
  GET_FAIL_WIDGET_SETTINGS,
  POSTING_WIDGET_SETTINGS,
  POST_SUCCESS_WIDGET_SETTINGS,
  POST_FAIL_WIDGET_SETTINGS,
  UPDATE_FIELD,
  UPDATE_THEME,
  UPLOAD_PHOTO_PREVIEW,
  UPLOAD_PHOTO_SUCCESS,
  CROP_OPEN,
  CROP_CLOSE,
  CROP_PHOTO,
  CROP_DELETE,
} from './ActionTypes'

// form and app actions

export const updateField = (fieldName, value, error) => ({ type: UPDATE_FIELD, fieldName, value, error })

export const updateTheme = () => ({ type: UPDATE_THEME })

// Actions to retrieve widget settings

export const gettingWidgetSettings = () => ({ type: GETTING_WIDGET_SETTINGS })

export const getSuccessWidgetSettings = widgetSettings => ({ type: GET_SUCCESS_WIDGET_SETTINGS, widgetSettings })

export const getFailWidgetSettings = () => ({ type: GET_FAIL_WIDGET_SETTINGS })

export const fetchWidgetSettings = (venueId, errHandler) =>
  srGet(`/api-yoa/venue/event_widget_settings`, { venue: venueId }).then(response => {
    if (!response.ok && errHandler) {
      errHandler(response)
    }
    return response.data
  })

export const tryGetWidgetSettings = venueId => dispatch => {
  dispatch(gettingWidgetSettings())
  const errHandler = () => dispatch(getFailWidgetSettings())
  fetchWidgetSettings(venueId, errHandler).then(data =>
    dispatch(
      getSuccessWidgetSettings({
        venueId,
        widgetTemplateTheme: data.widget_settings.widget_template_theme || 'LIGHT',
        buttonId: data.widget_settings.button_id || '1',
        enablePromoCodes: data.widget_settings.enablePromoCodes === 'true',
        enableDietaryRestrictions: data.widget_settings.enableDietaryRestrictions === 'true',
        enableSpecialOccasions: data.widget_settings.enableSpecialOccasions === 'true',
        displayMap: data.widget_settings.displayMap === 'true',
        font: data.widget_settings.font || '',
        reservationHoldTimeMins: data.widget_settings.reservation_hold_time_mins || '',
        partySizeType: data.widget_settings.party_size_type || '',
        birthdayType: data.widget_settings.birthdayType,
        salutationType: data.widget_settings.salutationType,
        enableFieldTime: data.widget_settings.enableFieldTime === 'true',
        enableFieldBirthday: data.widget_settings.enableFieldBirthday || '',
        enableFieldAdditionalInfo: data.widget_settings.enableFieldAdditionalInfo === 'true',
        buttonText: data.widget_settings.buttonText || '',
        enableSocialMediaLogin: data.widget_settings.enableSocialMediaLogin === 'true',
        colorPrimary: data.widget_settings.color_primary || '',
        buttonColor: data.widget_settings.color_button || '',
        fontsColorPrimary: data.widget_settings.fonts_color_primary || '',
        fontsColorSecondary: data.widget_settings.fonts_color_secondary || '',
        fontsColorButton: data.widget_settings.fonts_color_button || '',
        fontsColorLinks: data.widget_settings.fonts_color_links || '',
        fontsColorCheckoutInactive: data.widget_settings.fonts_color_checkout_inactive || '',
        fontsColorCheckoutActive: data.widget_settings.fonts_color_checkout_active || '',
        fontsColorError: data.widget_settings.fonts_color_error || '',
        colorBackground: data.widget_settings.color_background || '',
        colorBackgroundOpacity: data.widget_settings.color_background_opacity || '',
        colorCellBackground: data.widget_settings.color_cell_background || '',
        colorCellBackgroundOpacity: data.widget_settings.color_cell_background_opacity || '',
        colorLines: data.widget_settings.color_lines || '',
        colorLinesOpacity: data.widget_settings.color_lines_opacity || '',
        colorWidgetBackground: data.widget_settings.color_widget_background || '',
        colorWidgetBackgroundOpacity: data.widget_settings.color_widget_background_opacity || '',
        colorCheckoutBackground: data.widget_settings.color_checkout_background || '',
        colorCheckoutBackgroundOpacity: data.widget_settings.color_checkout_background_opacity || '',
        colorHoverOutline: data.widget_settings.color_hover_outline || '',
        colorHoverOutlineOpacity: data.widget_settings.color_hover_outline_opacity || '',
        recaptchaOn: data.widget_settings.recaptchaOn === 'true',
        venueGroupMarketingOn: data.widget_settings.venueGroupMarketingOn === 'true',
        tailoredCommunicationOn: data.widget_settings.tailoredCommunicationOn === 'true',
        venueSpecificMarketingOn: data.widget_settings.venueSpecificMarketingOn === 'true',
        venueSmsMarketingOn: data.widget_settings.venueSmsMarketingOn === 'true',
        isVenueGroupMarketingPolicyDefaultOn: data.widget_settings.isVenueGroupMarketingPolicyDefaultOn === 'true',
        isTailoredCommunicationPolicyDefaultOn: data.widget_settings.isTailoredCommunicationPolicyDefaultOn === 'true',
        isTailoredCommunicationPolicyDisabled: data.widget_settings.isTailoredCommunicationPolicyDisabled === 'true',
        isVenueSpecificMarketingPolicyDefaultOn: data.widget_settings.isVenueSpecificMarketingPolicyDefaultOn === 'true',
        isVenueSmsMarketingPolicyDefaultOn: data.widget_settings.isVenueSmsMarketingPolicyDefaultOn === 'true',
        isFeesInPriceDisplayed: data.widget_settings.isFeesInPriceDisplayed === 'true',
        aboveAgeConsentOn: data.widget_settings.aboveAgeConsentOn === 'true',
        displayReservationSmsOptIn: data.widget_settings.displayReservationSmsOptIn === 'true',
        showSmsOptInSettingsForTwilioUs: data.widget_settings.showSmsOptInSettingsForTwilioUs === 'true',
        reservationSmsOptInOn: data.widget_settings.reservationSmsOptInOn === 'true',
        remindersSmsEnabled: data.widget_settings.remindersSmsEnabled === 'true',
        smsBookingNotificationEnabled: data.widget_settings.smsBookingNotificationEnabled === 'true',
        showSpecialAttentionMessage: data.widget_settings.showSpecialAttentionMessage === 'true',
        ageToConsent: data.widget_settings.ageToConsent || '',
        textCustomPolicyHolderName: data.widget_settings.text_custom_policy_holder_name || '',
        textCustomPrivacyPolicyLink: data.widget_settings.text_custom_privacy_policy_link || '',
        textCustomGdprPolicyLink: data.widget_settings.text_custom_gdpr_policy_link || '',
        textCancellationPolicy: data.widget_settings.text_cancellation_policy || '',
        textMinimumSpend: data.widget_settings.text_minimum_spend || '',
        textServiceFee: data.widget_settings.text_service_fee || '',
        textVenueGroupMarketingOptIn: data.widget_settings.text_venue_group_marketing_opt_in || '',
        textVenueSpecificMarketingOptIn: data.widget_settings.text_venue_specific_marketing_opt_in || '',
        textVenueSmsMarketingOptIn: data.widget_settings.text_venue_sms_marketing_opt_in || '',
        textReservationSmsOptIn: data.widget_settings.text_reservation_sms_opt_in || '',
        textCustomPrivacyPolicyLinkLabel: data.widget_settings.text_custom_privacy_policy_link_label || '',
        textCustomGdprPolicyLinkLabel: data.widget_settings.text_custom_gdpr_policy_link_label || '',
        textTailoredCommunicationOptInLabel: data.widget_settings.text_tailored_communication_opt_in_label || '',
        textTailoredCommunicationOptInHeader: data.widget_settings.text_tailored_communication_opt_in_header || '',
        textTailoredCommunicationOptInBody: data.widget_settings.text_tailored_communication_opt_in_body || '',

        img_url: data.widget_settings.img_url || '',
        img_blob_key: data.widget_settings.img_blob_key || '',
        photo_dict: data.widget_settings.photo_dict || '',
        cropData: data.widget_settings.cropData || '',
        lightTheme: data.light_template || {},
        darkTheme: data.dark_template || {},
        isSmsMarketingEnabled: data.is_sms_marketing_enabled || false,

        floorplanAvailableColor: data.widget_settings.floorplanAvailableColor,
        floorplanAvailableOpacity: data.widget_settings.floorplanAvailableOpacity,
        floorplanUnavailableColor: data.widget_settings.floorplanUnavailableColor,
        floorplanSoldOutColor: data.widget_settings.floorplanSoldOutColor,
        floorplanSoldOutColorOpacity: data.widget_settings.floorplanSoldOutColorOpacity,
        floorplanUnavailableOpacity: data.widget_settings.floorplanUnavailableOpacity,
        floorplanClickColor: data.widget_settings.floorplanClickColor,
        floorplanClickOpacity: data.widget_settings.floorplanClickOpacity,
        floorplanHoverColor: data.widget_settings.floorplanHoverColor,
        floorplanHoverOpacity: data.widget_settings.floorplanHoverOpacity,
      })
    )
  )
}

// Actions to save widget settings

export const postingWidgetSettings = () => ({ type: POSTING_WIDGET_SETTINGS })

export const postSuccessWidgetSettings = () => ({ type: POST_SUCCESS_WIDGET_SETTINGS })

export const postFailWidgetSettings = () => ({ type: POST_FAIL_WIDGET_SETTINGS })

export const saveWidgetSettings = (venueId, widgetSettings, errHandler) =>
  srPost(`/api-yoa/venue/event_widget_settings`, { venue: venueId, ...widgetSettings }).then(response => {
    if (!response.ok && errHandler) {
      errHandler(response)
    }
    return {}
  })

export const tryPostWidgetSettings = widgetSettings => dispatch => {
  dispatch(postingWidgetSettings())
  const errHandler = () => dispatch(postFailWidgetSettings())
  saveWidgetSettings(
    widgetSettings.venueId,
    {
      venue_id: widgetSettings.venueId,
      widget_template_theme: widgetSettings.widgetTemplateTheme,
      font: widgetSettings.font,
      reservation_hold_time_mins: widgetSettings.reservationHoldTimeMins,
      party_size_type: widgetSettings.partySizeType,
      enable_field_time: widgetSettings.enableFieldTime,
      enableFieldBirthday: widgetSettings.enableFieldBirthday,
      enable_field_additional_info: widgetSettings.enableFieldAdditionalInfo,
      birthdayType: widgetSettings.birthdayType,
      salutationType: widgetSettings.salutationType,
      enableSocialMediaLogin: widgetSettings.enableSocialMediaLogin,
      color_primary: widgetSettings.colorPrimary,
      color_button: widgetSettings.buttonColor,
      fonts_color_primary: widgetSettings.fontsColorPrimary,
      fonts_color_secondary: widgetSettings.fontsColorSecondary,
      fonts_color_button: widgetSettings.fontsColorButton,
      fonts_color_links: widgetSettings.fontsColorLinks,
      fonts_color_checkout_inactive: widgetSettings.fontsColorCheckoutInactive,
      fonts_color_checkout_active: widgetSettings.fontsColorCheckoutActive,
      fonts_color_error: widgetSettings.fontsColorError,
      color_background: widgetSettings.colorBackground,
      color_background_opacity: widgetSettings.colorBackgroundOpacity,
      color_cell_background: widgetSettings.colorCellBackground,
      color_cell_background_opacity: widgetSettings.colorCellBackgroundOpacity,
      color_lines: widgetSettings.colorLines,
      color_lines_opacity: widgetSettings.colorLinesOpacity,
      color_widget_background: widgetSettings.colorWidgetBackground,
      color_widget_background_opacity: widgetSettings.colorWidgetBackgroundOpacity,
      color_checkout_background: widgetSettings.colorCheckoutBackground,
      color_checkout_background_opacity: widgetSettings.colorCheckoutBackgroundOpacity,
      color_hover_outline: widgetSettings.colorHoverOutline,
      color_hover_outline_opacity: widgetSettings.colorHoverOutlineOpacity,
      img_blob_key: widgetSettings.img_blob_key || '',
      img_url: widgetSettings.img_url || '',
      button_id: widgetSettings.buttonId,
      cropData: JSON.stringify(widgetSettings.cropData) || {},
      recaptchaOn: widgetSettings.recaptchaOn,
      buttonText: widgetSettings.buttonText,
      venueGroupMarketingOn: widgetSettings.venueGroupMarketingOn,
      tailoredCommunicationOn: widgetSettings.tailoredCommunicationOn,
      venueSpecificMarketingOn: widgetSettings.venueSpecificMarketingOn,
      venueSmsMarketingOn: widgetSettings.venueSmsMarketingOn,
      isVenueGroupMarketingPolicyDefaultOn: widgetSettings.isVenueGroupMarketingPolicyDefaultOn,
      isTailoredCommunicationPolicyDefaultOn: widgetSettings.isTailoredCommunicationPolicyDefaultOn,
      isTailoredCommunicationPolicyDisabled: widgetSettings.isTailoredCommunicationPolicyDisabled,
      isVenueSpecificMarketingPolicyDefaultOn: widgetSettings.isVenueSpecificMarketingPolicyDefaultOn,
      isVenueSmsMarketingPolicyDefaultOn: widgetSettings.isVenueSmsMarketingPolicyDefaultOn,
      displayReservationSmsOptIn: widgetSettings.displayReservationSmsOptIn,
      reservationSmsOptInOn: widgetSettings.reservationSmsOptInOn,
      remindersSmsEnabled: widgetSettings.remindersSmsEnabled,
      smsBookingNotificationEnabled: widgetSettings.smsBookingNotificationEnabled,
      showSpecialAttentionMessage: widgetSettings.showSpecialAttentionMessage,
      aboveAgeConsentOn: widgetSettings.aboveAgeConsentOn,
      isFeesInPriceDisplayed: widgetSettings.isFeesInPriceDisplayed,
      ageToConsent: widgetSettings.ageToConsent,
      text_custom_policy_holder_name: widgetSettings.textCustomPolicyHolderName,
      text_custom_privacy_policy_link: widgetSettings.textCustomPrivacyPolicyLink,
      text_custom_gdpr_policy_link: widgetSettings.textCustomGdprPolicyLink,
      enablePromoCodes: widgetSettings.enablePromoCodes,
      enableDietaryRestrictions: widgetSettings.enableDietaryRestrictions,
      enableSpecialOccasions: widgetSettings.enableSpecialOccasions,
      displayMap: widgetSettings.displayMap,
      text_cancellation_policy: widgetSettings.textCancellationPolicy,
      text_venue_group_marketing_opt_in: widgetSettings.textVenueGroupMarketingOptIn,
      text_venue_specific_marketing_opt_in: widgetSettings.textVenueSpecificMarketingOptIn,
      text_reservation_sms_opt_in: widgetSettings.textReservationSmsOptIn,
      text_tailored_communication_opt_in_label: widgetSettings.textTailoredCommunicationOptInLabel,
      text_tailored_communication_opt_in_header: widgetSettings.textTailoredCommunicationOptInHeader,
      text_tailored_communication_opt_in_body: widgetSettings.textTailoredCommunicationOptInBody,
      text_custom_privacy_policy_link_label: widgetSettings.textCustomPrivacyPolicyLinkLabel,
      text_custom_gdpr_policy_link_label: widgetSettings.textCustomGdprPolicyLinkLabel,
      text_minimum_spend: widgetSettings.textMinimumSpend,
      text_service_fee: widgetSettings.textServiceFee,

      floorplanClickColor: widgetSettings.floorplanClickColor,
      floorplanClickOpacity: widgetSettings.floorplanClickOpacity,
      floorplanHoverColor: widgetSettings.floorplanHoverColor,
      floorplanHoverOpacity: widgetSettings.floorplanHoverOpacity,
      floorplanAvailableColor: widgetSettings.floorplanAvailableColor,
      floorplanAvailableOpacity: widgetSettings.floorplanAvailableOpacity,
      floorplanUnavailableColor: widgetSettings.floorplanUnavailableColor,
      floorplanSoldOutColor: widgetSettings.floorplanSoldOutColor,
      floorplanSoldOutColorOpacity: widgetSettings.floorplanSoldOutColorOpacity,
      floorplanUnavailableOpacity: widgetSettings.floorplanUnavailableOpacity,
    },
    errHandler
  ).then(() => {
    document.getElementById('widget-iframe').contentWindow.location.reload()
    return dispatch(postSuccessWidgetSettings())
  })
}

export const uploadPhotoPreview = preview => ({ type: UPLOAD_PHOTO_PREVIEW, preview })

export const uploadPhotoSuccess = upload => ({ type: UPLOAD_PHOTO_SUCCESS, upload })

export const uploadPhoto = file => dispatch => {
  dispatch(uploadPhotoPreview(file.preview))
  dispatch(cropOpen())
  return srPost(`/upload-url`, { rurl: Math.random().toString() }, { convertToGetParams: true })
    .then(response => (response.error ? response : srUpload(response.upload_url, file)))
    .then(uploadData => {
      dispatch(uploadPhotoSuccess(uploadData))
    })
}

export const cropOpen = () => ({ type: CROP_OPEN })

export const cropImage = coords => ({ type: CROP_PHOTO, coords })

export const closeCrop = () => ({ type: CROP_CLOSE })

export const cropDelete = () => ({ type: CROP_DELETE })
